import React, { useEffect, useState } from "react";
import "./section11.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section11 = () => {
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${SERVER_URL}/home/sectionEleven/list`
        );
        if (response.data && response.data.length > 0) {
          setInfo(response.data[0]);
        } else {
          setError("No data available for Section Eleven.");
        }
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchInfo();
  }, []);

  if (loading) {
    return <div className="section11-loading">Loading...</div>;
  }

  if (error) {
    return <div className="section11-error">{error}</div>;
  }

  if (!info) {
    return <div className="section11-error">No valid data to display.</div>;
  }

  return (
    <section>
      <div className="section11-container">
        <div className="section11-container-details">
          <div className="section11-heading">
            <div className="span-shape">
              <div className="shape"></div>
              <span>{info.title}</span>
            </div>
            <h3>{info.heading}</h3>
            <p>{info.description}</p>
          </div>
          <div className="section11-details">
            {info.logos.map((logo, index) => (
              <div className="logo-container" key={index}>
                <img
                  src={`${SERVER_URL}/${logo.logo}`}
                  alt={`Client Logo ${index + 1}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section11;
