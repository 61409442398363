import React, { useEffect, useState } from "react";
import "./section10.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section10 = () => {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${SERVER_URL}/home/sectionTen/list`);
        if (response.data) {
          setInfo(response.data);
        } else {
          setError("No data available for Section Ten.");
        }
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchInfo();
  }, []);

  if (loading) {
    return <div className="section10-loading">Loading...</div>;
  }

  if (error) {
    return <div className="section10-error">{error}</div>;
  }

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section>
      <div className="section10-container">
        <div className="section10-container-details">
          <div className="section10-heading">
            <div className="span-shape">
              <div className="shape"></div>
              <span>{info[0].title}</span>
            </div>
            <h3>{info[0].heading}</h3>
            <p>{info[0].description}</p>
          </div>
          <div className="section10-details">
            {info[0].faqs.map((faq, index) => (
              <div
                className={`faq-item ${activeIndex === index ? "active" : ""}`}
                key={index}
              >
                <div className="faq-question" onClick={() => toggleFaq(index)}>
                  {faq.question}
                  <span className="faq-icon">
                    {activeIndex === index ? "-" : "+"}
                  </span>
                </div>
                {activeIndex === index && (
                  <div className="faq-answer">
                    {faq.answers.map((answer, i) => (
                      <p key={i}>{answer}</p>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section10;
