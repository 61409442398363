import React, { useEffect, useState } from "react";
import "./section7.scss";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section7 = () => {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${SERVER_URL}/home/sectionSeven/list`
        );
        if (response.data) {
          setInfo(response.data);
        } else {
          setError("No data available for Section Seven.");
        }
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchInfo();
  }, []);

  if (loading) {
    return <div className="section7-loading">Loading...</div>;
  }

  if (error) {
    return <div className="section7-error">{error}</div>;
  }

  if (!info.length || !info[0]?.skills?.length) {
    return (
      <div className="section7-empty">No skills available to display.</div>
    );
  }

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section>
      <div className="section7-container">
        <div className="section7-container-details">
          <div className="section7-heading">
            <div className="span-shape">
              <div className="shape"></div>
              <span>{info[0].title}</span>
            </div>
            <h3>{info[0].heading}</h3>
            <p>{info[0].description}</p>
          </div>
          <div className="section7-details">
            {info[0].skills.map((item, index) => (
              <div className="details-container" key={index}>
                <div
                  className="details-header"
                  onClick={() => toggleExpand(index)}
                >
                  <h4>{item.heading}</h4>
                  {expandedIndex === index ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </div>
                {expandedIndex === index && (
                  <div className="details-content">
                    <img
                      src={`${SERVER_URL}/${item.image}`}
                      alt={item.heading}
                    />
                    <p>{item.details}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section7;
