import React, { useEffect, useState } from "react";
import "./section5.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section5 = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${SERVER_URL}/home/sectionFive/list`);
        if (response.data) {
          setInfo(response.data);
        } else {
          setError("No data available for Section Five.");
        }
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchInfo();
  }, []);

  if (loading) {
    return <div className="section5-loading">Loading...</div>;
  }

  if (error) {
    return <div className="section5-error">{error}</div>;
  }

  if (!info.length || !info[0]?.industries?.length) {
    return (
      <div className="section5-empty">No industries available to display.</div>
    );
  }

  return (
    <section>
      <div className="section5-container">
        <div className="section5-container-details">
          <div className="section5-heading">
            <div className="span-shape">
              <div className="shape"></div>
              <span>{info[0].title}</span>
            </div>
            <h3>{info[0].heading}</h3>
            <p>{info[0].description}</p>
          </div>

          <div className="section5-tabs">
            <div className="vertical-tabs">
              {info[0].industries.map((industry, index) => (
                <div
                  key={index}
                  className={`tab-item ${activeTab === index ? "active" : ""}`}
                  onClick={() => setActiveTab(index)}
                >
                  <img
                    src={`${SERVER_URL}/${industry.icon}`}
                    alt={industry.heading}
                    className="tab-icon"
                  />
                  <h4 className="tab-heading">{industry.heading}</h4>
                </div>
              ))}
            </div>

            <div className="tab-content">
              <div className="tab-content-body">
                <div className="tab-body-content-heading">
                  <img
                    src={`${SERVER_URL}/${info[0].industries[activeTab].icon}`}
                    alt={info[0].industries[activeTab].heading}
                    className="content-icon"
                  />
                  <h4 className="content-heading">
                    {info[0].industries[activeTab].heading}
                  </h4>
                </div>
                <span>{info[0].industries[activeTab].details}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section5;
