import React, { useState, useEffect } from "react";
import "./section12.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section12 = () => {
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${SERVER_URL}/home/sectionTwelve/list`
        );
        if (response.data && response.data.length > 0) {
          setInfo(response.data[0]);
        } else {
          setError("No data available for Section Twelve.");
        }
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchInfo();
  }, []);

  useEffect(() => {
    if (info && info.testimonials && info.testimonials.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === info.testimonials.length - 1 ? 0 : prevIndex + 1
        );
      }, 15000);

      return () => clearInterval(intervalId);
    }
  }, [info]);

  if (loading) {
    return <div className="section12-loading">Loading...</div>;
  }

  if (error) {
    return <div className="section12-error">{error}</div>;
  }

  if (!info || !info.testimonials || info.testimonials.length === 0) {
    return <div className="section12-error">No valid data to display.</div>;
  }

  const renderStars = (rating) => {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i} className={`star ${i <= rating ? "filled" : ""}`}>
          ★
        </span>
      );
    }
    return stars;
  };

  const currentTestimonial = info.testimonials[currentIndex];

  return (
    <section>
      <div className="section12-container">
        <div className="section12-container-details">
          <div className="section12-heading">
            <div className="span-shape">
              <div className="shape"></div>
              <span>{info.title}</span>
            </div>
            <h3>{info.heading}</h3>
            <p>{info.description}</p>
          </div>
          <div className="section12-details">
            <div className="testimonial-container">
              <div className="testimonial-image">
                <img
                  src={`${SERVER_URL}/${currentTestimonial.image}`}
                  alt={currentTestimonial.name}
                />
              </div>
              <div className="testimonial-info">
                <h4>{currentTestimonial.name}</h4>
                <p className="designation">
                  {currentTestimonial.designation} at{" "}
                  {currentTestimonial.company}
                </p>
                <div className="rating">
                  {renderStars(currentTestimonial.ratings)}
                </div>
                <p className="testimonial">{currentTestimonial.testimonial}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section12;
