import React, { useState, useEffect } from "react";
import "./section1.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section1 = () => {
  const [info, setInfo] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/home/sectionOne/list`);
        setInfo(response.data);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchInfo();
  }, []);

  useEffect(() => {
    if (info.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % info.length);
      }, 15000);

      return () => clearInterval(interval);
    }
  }, [info.length]);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section>
      <div className="section1-container">
        {info.length > 0 ? (
          <>
            <div className="section1-details">
              <div className="section1-intro">
                <div className="span-shape">
                  <div className="shape"></div>
                  <span>{info[currentIndex].title}</span>
                </div>
                <div>
                  <h3>{info[currentIndex].heading}</h3>
                  <p>{info[currentIndex].description}</p>
                  <button className="primary-button">
                    {info[currentIndex].button}
                  </button>
                </div>
              </div>
              <div className="section1-image">
                <img
                  src={`${SERVER_URL}/${info[currentIndex].image}`}
                  alt={info[currentIndex].heading}
                />
              </div>
            </div>

            <div className="dots-container">
              {info.map((_, index) => (
                <div
                  key={index}
                  className={`dot ${currentIndex === index ? "active" : ""}`}
                  onClick={() => handleDotClick(index)}
                />
              ))}
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </section>
  );
};

export default Section1;
