import React, { useEffect, useState } from "react";
import "./section3.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section3 = () => {
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [counters, setCounters] = useState([]);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/home/sectionThree/list`
        );
        setInfo(response.data);
        setCounters(response.data[0]?.achievements.map(() => 0));
      } catch (error) {
        console.error("Error fetching Section Three:", error.message);
        setError("Error loading data.");
      } finally {
        setLoading(false);
      }
    };
    fetchInfo();
  }, []);

  useEffect(() => {
    if (info?.[0]) {
      const durations = 2000;
      const frames = 60;
      const totalSteps = Math.ceil(durations / (1000 / frames));

      info[0].achievements.forEach((item, index) => {
        const end = item.value;
        const increment = Math.ceil(end / totalSteps);

        let currentStep = 0;
        const interval = setInterval(() => {
          setCounters((prev) => {
            const updatedCounters = [...prev];
            updatedCounters[index] = Math.min(
              updatedCounters[index] + increment,
              end
            );
            return updatedCounters;
          });
          currentStep += 1;
          if (currentStep >= totalSteps) {
            clearInterval(interval);
          }
        }, 1000 / frames);
      });
    }
  }, [info]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="section3-container">{error}</div>;
  }

  if (!info || info.length === 0) {
    return <div>No data available for Section Three.</div>;
  }

  return (
    <section>
      <div className="section3-container">
        <div className="section3-details">
          <div className="section3-heading">
            <div className="span-shape">
              <div className="shape"></div>
              <span>{info[0]?.title}</span>
            </div>
            <h3>{info[0]?.heading}</h3>
            <p>{info[0]?.description}</p>
          </div>
          <div className="section3-grid-container">
            {info[0]?.achievements?.map((item, index) => (
              <div className="container" key={index}>
                <span>{counters[index]}+</span>
                <p>{item.achievement}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;
