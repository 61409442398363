import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Services from "./pages/services/Services";
import Products from "./pages/products/Products";
import Industries from "./pages/industries/Industries";
import Blog from "./pages/blog/Blog";
import Career from "./pages/career/Career";
import Contact from "./pages/contact/Contact";
import Login from "./pages/login-signup/login/Login";
import Signup from "./pages/login-signup/signup/Signup";
import Footer from "./components/footer/Footer";
import ScrollUp from "./components/scrollup/ScrollUp";
import ChatbotIcon from "./components/chatboticon/ChatbotIcon";

function App() {
  return (
    <BrowserRouter>
      <>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/products" element={<Products />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
        <Footer />
        <ScrollUp />
        <ChatbotIcon />
      </>
    </BrowserRouter>
  );
}

export default App;
