import React, { useState, useEffect } from "react";
import "./section4.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section4 = () => {
  
  const [activeTab, setActiveTab] = useState(0);
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${SERVER_URL}/home/sectionFour/list`);
        if (response.data) {
          setInfo(response.data);
        } else {
          setError("No data available for Section Four.");
        }
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchInfo();
  }, []);

  if (loading) {
    return <div className="section4-loading">Loading...</div>;
  }

  if (error) {
    return <div className="section4-error">{error}</div>;
  }

  if (!info.length || !info[0]?.services?.length) {
    return (
      <div className="section4-empty">No services available to display.</div>
    );
  }

  return (
    <section>
      <div className="section4-container">
        <div className="section4-container-details">
          <div className="section4-heading">
            <div className="span-shape">
              <div className="shape"></div>
              <span>{info[0]?.title}</span>
            </div>
            <h3>{info[0]?.heading}</h3>
            <p>{info[0]?.description}</p>
          </div>
          <div className="section4-tab-container">
            <div className="tabs">
              {info[0].services.map((service, index) => (
                <button
                  key={index}
                  className={`tab ${activeTab === index ? "active" : ""}`}
                  onClick={() => setActiveTab(index)}
                >
                  {service.heading}
                </button>
              ))}
            </div>
            <div className="tab-content">
              <div className="tab-content-body">
                <div className="content-image">
                  <img
                    src={`${SERVER_URL}/${info[0].services[activeTab]?.image}`}
                    alt={info[0].services[activeTab]?.heading}
                  />
                </div>
                <div className="content-description">
                  <h4>{info[0].services[activeTab]?.heading}</h4>
                  <p>{info[0].services[activeTab]?.description}</p>
                  <ul>
                    {info[0].services[activeTab]?.detailsPoints?.map(
                      (point, index) => (
                        <li key={index}>
                          <span>{point}</span>
                        </li>
                      )
                    )}
                  </ul>
                  <button className="primary-button">
                    {info[0].services[activeTab]?.button}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section4;
