import React, { useEffect, useState } from "react";
import "./section8.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section8 = () => {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${SERVER_URL}/home/sectionEight/list`
        );
        if (response.data) {
          setInfo(response.data);
        } else {
          setError("No data available for Section Seven.");
        }
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchInfo();
  }, []);

  if (loading) {
    return <div className="section8-loading">Loading...</div>;
  }

  if (error) {
    return <div className="section8-error">{error}</div>;
  }

  if (!info.length || !info[0]?.insights?.length) {
    return (
      <div className="section8-empty">No skills available to display.</div>
    );
  }

  const insights = info[0].insights.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const truncateContent = (contents) => {
    const concatenatedString = contents.join(" ");
    return concatenatedString.length > 70
      ? concatenatedString.substring(0, 70) + "..."
      : concatenatedString;
  };

  return (
    <section>
      <div className="section8-container">
        <div className="section8-container-details">
          <div className="section8-heading">
            <div className="span-shape">
              <div className="shape"></div>
              <span>{info[0].title}</span>
            </div>
            <h3>{info[0].heading}</h3>
            <p>{info[0].description}</p>
          </div>
          <div className="section8-details">
            <div className="scrolling-wrapper">
              {insights.concat(insights).map((insight, index) => (
                <div className="card" key={index}>
                  <img
                    src={`${SERVER_URL}/${insight.image}`}
                    alt={`Insight ${index + 1}`}
                  />
                  <div className="card-content">
                    <h4>{insight.heading}</h4>
                    <p>
                      By {insight.author} |{" "}
                      <span>{new Date(insight.date).toLocaleDateString()}</span>
                    </p>
                    <div className="content-truncate">
                      {truncateContent(insight.contents)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section8;
