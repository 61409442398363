import React, { useEffect, useState } from "react";
import "./section2.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section2 = () => {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/home/sectionTwo/list`);
        setInfo(response.data);
      } catch (error) {
        console.error(error.message);
        setError("Loading...");
      } finally {
        setLoading(false);
      }
    };
    fetchInfo();
  }, []);

  if (loading) {
    return <div className="section2-container">Loading...</div>;
  }

  if (error) {
    return <div className="section2-container">{error}</div>;
  }

  if (!info || info.length === 0) {
    return <div className="section2-container">No data available.</div>;
  }

  const section = info[0];

  return (
    <section>
      <div className="section2-container">
        <div className="section2-details">
          {section.image && (
            <div className="section2-image">
              <img
                src={`${SERVER_URL}/${section.image}`}
                alt="Section visual"
              />
            </div>
          )}
          <div className="section2-intro">
            <div className="span-shape">
              <div className="shape"></div>
              <span>{section.title || "Default Title"}</span>
            </div>
            <div>
              <h3>{section.heading || "Default Heading"}</h3>
              {section.description && Array.isArray(section.description) ? (
                section.description.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))
              ) : (
                <p>No description available.</p>
              )}
              {section.button && (
                <button className="primary-button">{section.button}</button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
