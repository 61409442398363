import React, { useEffect, useState } from "react";
import "./section6.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section6 = () => {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${SERVER_URL}/home/sectionSix/list`);
        if (response.data) {
          setInfo(response.data);
        } else {
          setError("No data available for Section Six.");
        }
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchInfo();
  }, []);

  if (loading) {
    return <div className="section6-loading">Loading...</div>;
  }

  if (error) {
    return <div className="section6-error">{error}</div>;
  }

  if (!info.length || !info[0]?.processes?.length) {
    return (
      <div className="section6-empty">No processes available to display.</div>
    );
  }

  return (
    <section>
      <div className="section6-container">
        <div className="section6-container-details">
          <div className="section6-heading">
            <div className="span-shape">
              <div className="shape"></div>
              <span>{info[0].title}</span>
            </div>
            <h3>{info[0].heading}</h3>
            <p>{info[0].description}</p>
          </div>
          <div className="section6-details">
            {info[0].processes.map((item, index) => (
              <div className="section6-grid-card" key={index}>
                <div className="details-card-heading">
                  <div className="card-icon">
                    <img src={`${SERVER_URL}/${item.icon}`} alt="itemIcon" />
                  </div>
                  <h2 className="card-heading">{item.heading}</h2>
                </div>
                <ul className=".card-description">
                  {item.details.map((process, index) => (
                    <li key={index}>
                      <span>{process}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section6;
