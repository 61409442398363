import React, { useEffect, useState } from "react";
import "./section9.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Section9 = () => {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [newSection, setNewSection] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${SERVER_URL}/home/sectionNine/list`);
        if (response.data) {
          setInfo(response.data);
        } else {
          setError("No data available for Section Nine.");
        }
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchInfo();
  }, []);

  if (loading) {
    return <div className="section9-loading">Loading...</div>;
  }

  if (error) {
    return <div className="section9-error">{error}</div>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSection({ ...newSection, [name]: value });
  };

  const handleSendMessage = async () => {};

  return (
    <section>
      <div className="section9-container">
        <div className="section9-container-details">
          <div className="section9-heading">
            <div className="span-shape">
              <div className="shape"></div>
              <span>{info[0].title}</span>
            </div>
            <h3>{info[0].heading}</h3>
            <p>{info[0].description}</p>
          </div>
          <div className="section9-details">
            <div className="details-info">
              <h3>{info[0].question}</h3>
              <ul>
                {info[0].answers.map((paragraph, index) => (
                  <li key={index}>
                    <p>{paragraph}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="details-form">
              <form method="POST" onSubmit={handleSendMessage}>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    value={newSection.name}
                    onChange={handleInputChange}
                    required
                  />
                  <label htmlFor="name">Full Name</label>
                </div>
                <div className="form-contents">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      value={newSection.email}
                      onChange={handleInputChange}
                      required
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="phone"
                      value={newSection.phone}
                      onChange={handleInputChange}
                      required
                    />
                    <label htmlFor="phone">Phone</label>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    type="text"
                    name="description"
                    value={newSection.description}
                    onChange={handleInputChange}
                    required
                  />
                  <label htmlFor="description">Description</label>
                </div>
                {info[0].checkOptions.map((option, index) => (
                  <div className="check-group" key={index}>
                    <input
                      type="checkbox"
                      id={`checkOption-${index}`}
                      name={`checkOption-${index}`}
                      value={option}
                    />
                    <label htmlFor={`checkOption-${index}`}>{option}</label>
                  </div>
                ))}

                <button
                  className="primary-button"
                  style={{ width: "max-content" }}
                  type="submit"
                >
                  {info[0].button}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section9;
